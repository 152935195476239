<template>
  <div class="w-full">
    <div class="flex flex-col text-primary">

      <div class="lb-navigation">

        <div class="col-span-1 flex items-center lg:hidden">
          <UIMobileMenuButton />
          <UIMobileSearchButton />
        </div>

        <!-- Logo -->
        <div class="order-3 col-span-4 lg:col-span-1 flex justify-center lg:justify-start items-center flex-[0_0_auto]">
          <Logo class="lb-nav-logo order-2"/>

        </div>

        <!-- Arama Formu -->
        <SearchInput class="order-3 lg:col-span-4 hidden lg:flex" is-expandabled="true"/>

        <!-- Üst Sağ - Kullanıcı Menu Alanı-->
        <div class="col-span-1 lg:col-span-1 order-4">
          <LayoutNavigationUserContentArea class="!justify-end" />
        </div>

      </div>

      <LayoutNavigationMenu class="relative w-full px-4" v-if="isShowMenu"/>

    </div>

    <SearchInput class="w-full flex lg:hidden" v-if="isShowMobileSearch" is-expandabled="true"/>

  </div>
</template>

<script setup>
const {isShowMenu, isShowMobileSearch} = useHelper()
</script>